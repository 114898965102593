<template>
  <div v-if="plan">
    <div
      v-if="plan.type.toLowerCase() === 'free' && isMembersExhausted"
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#C63434] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1666278526/getequityV2/error_scmnuc.png"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        On the Trial plan, you can only have 10 members. To add more members,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
    <div
      v-if="plan.type.toLowerCase() === 'free' && isDealsExhausted && showDeals"
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#C63434] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1666278526/getequityV2/error_scmnuc.png"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        You have exhausted your deal limit. To create more deals,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
    <div
      v-if="plan.type.toLowerCase() === 'free' && isMembersHalfwayExhausted"
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#4D84FF] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1685109561/getequityV2/Fill_mozwcl.svg"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        Invitation Limit: You can add {{ membersLeft }} more members. To get
        more membership slots,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
    <div
      v-if="
        plan.type.toLowerCase() === 'free' &&
          isDealsHalfwayExhausted &&
          showDeals
      "
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#4D84FF] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1685109561/getequityV2/Fill_mozwcl.svg"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        Deal Limit: You can create {{ membersLeft }} more deals. To create more
        deals,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
    <div
      v-if="plan.type.toLowerCase() === 'free' && isMembersAlmostExhausted"
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#F38B00] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1661781045/getequityV2/info-orange_o9aegn.svg"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        Invitation Limit: You can add {{ membersLeft }} more members. To get
        more membership slots,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
    <div
      v-if="
        plan.type.toLowerCase() === 'free' &&
          isDealsAlmostExhausted &&
          showDeals
      "
      class="tw-h-14 tw-border tw-border-[#F1F3F6] tw-flex tw-py-4 tw-px-6 tw-items-center tw-border-l-[#F38B00] tw-border-l-2 tw-mb-7 tw-gap-2 tw-bg-white lg:tw-mx-4 tw-rounded-tl-[4px] tw-rounded-bl-[4px]"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1661781045/getequityV2/info-orange_o9aegn.svg"
        alt="error icon"
      />
      <p class="tw-text-xs tw-text-black">
        Deal Limit: You can create {{ membersLeft }} more deals. To create more
        deals,
        <button
          @click="$emit('show-plans')"
          class="tw-text-[#2A62F5] tw-underline hover:tw-bg-white hover:tw-text-[#2A62F5]"
        >
          upgrade your plan
        </button>
      </p>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from "vue";
  import { useStore } from "vuex";

  const store = useStore();
  defineEmits(["show-plans"]);

  const dealsLimit = ref(5);
  const props = defineProps({
    showDeals: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  });
  const showDeals = computed(() => props.showDeals);

  const plan = computed(() => store.getters["organisationModule/plan"]);
  const dealRequests = computed(() => store.getters["Deals/dealRequests"]);
  const organisationDetails = computed(
    () => store.getters["organisationModule/organisationDetails"]
  );
  const deals = computed(() => {
    const deals = dealRequests.value?.filter(
      (deal) => deal.status === "Approved"
    );
    return deals;
  });

  const membersLeft = computed(() => {
    const membersLimit = plan.value.members_limit;
    const currentMembersCount = organisationDetails.value.members_count;
    const membersLeft = membersLimit - currentMembersCount;
    return membersLeft;
  });
  const dealsLeft = computed(() => {
    const currentDealCount = deals.value?.length;
    const dealsLeft =
      dealsLimit - currentDealCount > 0
        ? dealsLimit.value - currentDealCount
        : 0;
    return dealsLeft;
  });
  const isMembersExhausted = computed(() => {
    return organisationDetails.value.members_count >= plan.value.members_limit;
  });
  const isDealsExhausted = computed(() => {
    return deals.value?.length >= 5;
  });
  const isMembersHalfwayExhausted = computed(() => {
    const membersLimit = plan.value.members_limit;
    let isMembersHalfwayExhausted = false;
    if (membersLeft.value === membersLimit / 2) {
      isMembersHalfwayExhausted = true;
    } else {
      isMembersHalfwayExhausted = false;
    }
    return isMembersHalfwayExhausted;
  });
  const isDealsAlmostExhausted = computed(() => {
    let isDealsAlmostExhausted = false;
    if (dealsLeft.value < dealsLimit.value / 2 && dealsLeft.value !== 0) {
      isDealsAlmostExhausted = true;
    } else {
      isDealsAlmostExhausted = false;
    }
    return isDealsAlmostExhausted;
  });
  const isMembersAlmostExhausted = computed(() => {
    const membersLimit = plan.value.members_limit;
    let isMembersAlmostExhausted = false;
    if (
      membersLeft.value < membersLimit &&
      membersLeft.value > 0 &&
      membersLeft.value < Math.ceil(membersLimit / 2)
    ) {
      isMembersAlmostExhausted = true;
    } else {
      isMembersAlmostExhausted = false;
    }
    return isMembersAlmostExhausted;
  });
  const isDealsHalfwayExhausted = computed(() => {
    let isDealsHalfwayExhausted = false;
    if (dealsLeft.value === dealsLimit / 2) {
      isDealsHalfwayExhausted = true;
    } else {
      isDealsHalfwayExhausted = false;
    }
    return isDealsHalfwayExhausted;
  });

  const getData = async () => {
    await Promise.all([
      store.dispatch("Deals/getDealRequests"),
      store.dispatch("organisationModule/getOrganisationPlan"),
    ]);
  };

  getData();
</script>

<style lang="scss" scoped></style>
