import { ref, watch } from "vue";

export function useValidation() {
  // const emit = defineEmits(["submit-data"]);
  const validationResult = ref([]);
  const fields = ref(null); // number of fields on the form
  const validate = ref(false);
  const canSubmit = ref(false);

  const checkFormValidation = () => {
    validate.value = true;
    canSubmit.value = false;
    validationResult.value = [];
    setTimeout(() => {
      validate.value = false;
    }, 1500);
  };

  const handleValidation = (val) => {
    validationResult.value.push(val);
  };

  watch(
    validationResult,
    (value) => {
      //check that the length of validated fields is equal to the number of fields being validated
      if (value.length === fields.value && !value.includes(false)) {
        canSubmit.value = true;
      } else {
        // console.log(value);
      }
    },
    { deep: true }
  );

  return {
    fields,
    checkFormValidation,
    handleValidation,
    validate,
    canSubmit,
  };
}
