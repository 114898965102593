<template>
  <div class="tw-w-full tw-mt-6">
    <button
      type="submit"
      class="tw-w-full tw-bg-primary tw-rounded tw-text-sm tw-text-white tw-p-4"
      :disabled="loading"
    >
      <span v-show="!loading">{{ title }}</span>
      <img
        v-show="loading"
        class="tw-m-auto"
        src="@/assets/img/spinner.svg"
        alt="animated spinner"
      />
    </button>
  </div>
</template>

<script>
  export default {
    name: "OnboardingBtn",

    emits: ["btnClicked"],

    props: {
      title: { type: String, default: () => "", required: true },
      loading: { type: Boolean, default: () => false },
    },
  };
</script>

<style lang="scss" scoped></style>
