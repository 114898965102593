<template>
  <div
    id="medium-modal"
    class="tw-fixed tw-top-0 tw-w-full tw-h-screen tw-right-0 tw-z-40"
    @click="$emit('close')"
  >
    <div class="container tw-m-4">
      <div class="card tw-relative tw-bg-white tw-rounded-lg" @click.stop>
        <img
          class="tw-absolute tw-top-4 tw-right-4 sm:tw-top-6 sm:tw-right-6 tw-w-4 tw-cursor-pointer"
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1635485821/getequityV2/close_muxdyb.webp"
          alt="close icon"
          @click="$emit('close')"
        />
        <div class="slot tw-px-4 tw-py-8 sm:tw-p-12">
          <slot name="content">content</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MediumModal",

    emits: ["close"],

    methods: {},
  };
</script>

<style lang="scss" scoped>
  #medium-modal {
    @include fadeIn;
    background-color: $modal-bg;
    overflow: scroll;

    .container {
      .card {
        max-width: 530px;
        margin: 7rem auto 0 auto;
      }
    }
  }
</style>
