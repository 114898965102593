<template>
  <Modal :show="show" @close-modal="$emit('close-send-deal')">
    <div class="send-deal tw-relative">
      <div v-if="dealInfo">
        <h1 class="tw-font-medium  tw-text-xl tw-text-secondary tw-mt-4">
          Send {{ dealInfo.name }} Deal
        </h1>
        <p class="tw-text-black-light tw-mb-6">
          Balance: {{ convertToLocale(dealLeft) }}
        </p>
      </div>
      <div v-else class="">
        <h1 class="tw-font-medium tw-text-xl tw-text-secondary tw-mt-4">
          Send Deal
        </h1>
        <p class="tw-text-sm tw-text-black-light tw-mt-1">
          Only public deals are listed here here.
        </p>
      </div>

      <form @submit.prevent="verifyCode" class="tw-mt-10 send-deal__form">
        <div v-if="deals" class="tw-relative tw-mb-4">
          <label for="deal"></label>
          <select
            class="tw-w-full tw-text-sm tw-border tw-rounded tw-px-5 tw-py-3 tw-cursor-pointer"
            name="deal"
            id="deal"
            v-model="deal"
            required
          >
            <option value="" selected>Select Deal</option>
            <option
              class="tw-flex tw-items-center"
              v-for="(deal, index) in deals"
              :value="deal.symbol"
              :key="index"
            >
              <p>
                {{ deal.name }}
              </p>
            </option>
          </select>
          <img
            src="@/assets/img/solid-chevron-down.svg"
            class="tw-absolute tw-top-5 tw-right-4 tw-bottom-6"
            alt="chevron icon"
          />
        </div>
        <input
          v-focus
          class="send-deal__input tw-h-12 tw-w-full tw-pl-4"
          type="number"
          v-model="amount"
          name="amount"
          id="dealAmount"
          placeholder="Amount of Deal to send"
        />
        <p
          class="tw-mt-2 tw-text-red-600 tw-text-sm"
          v-if="v$.amount.$errors.length > 0"
        >
          {{ amountError.$message }}
        </p>
        <p
          v-if="dealInfo"
          class="tw-text-black-light tw-text-sm tw-w-full tw-text-right"
        >
          $10/{{ dealInfo.symbol }}
        </p>
        <p
          class="tw-text-base tw-text-black-light tw-text-center tw-mt-4 tw-mb-2"
        >
          Enter your transaction pin
        </p>
        <div class="tw-flex tw-justify-center tw-items-center" ref="codeInput">
          <OnboardingCodeInput
            label="pin"
            type="password"
            :digit="digit.firstDigit"
            :error="errorOne || {}"
            :position="1"
            :disabled="loading"
            @input="setDigit"
            @handlePaste="handlePaste"
          />
          <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
          <OnboardingCodeInput
            label="pin"
            type="password"
            :digit="digit.secondDigit"
            :error="errorTwo || {}"
            :position="2"
            :disabled="loading"
            @input="setDigit"
            @handlePaste="handlePaste"
          />
          <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
          <OnboardingCodeInput
            label="pin"
            type="password"
            :digit="digit.thirdDigit"
            :error="errorThree || {}"
            :position="3"
            :disabled="loading"
            @input="setDigit"
            @handlePaste="handlePaste"
          />
          <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
          <OnboardingCodeInput
            label="pin"
            type="password"
            :digit="digit.forthDigit"
            :error="errorFour || {}"
            :position="4"
            :disabled="loading"
            @input="setDigit"
            @handlePaste="handlePaste"
          />
        </div>
        <OnboardingBtn :loading="loading" title="Send deal" />
      </form>
    </div>
  </Modal>
</template>

<script>
  import Modal from "@/components/general/Modal";
  import OnboardingCodeInput from "../onboarding/OnboardingCodeInput.vue";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn.vue";
  import useValidate from "@vuelidate/core";
  import { required, maxLength } from "@vuelidate/validators";
  import { mapActions } from "vuex";
  import { convertToLocale } from "@/utils/helpers.js";
  export default {
    name: "sendDeal",
    components: {
      Modal,
      OnboardingCodeInput,
      OnboardingBtn,
    },
    data() {
      return {
        v$: useValidate(),
        loading: false,
        digit: {
          firstDigit: "",
          secondDigit: "",
          thirdDigit: "",
          forthDigit: "",
        },
        code: "",
        deal: "",
        amount: "",
        fields: [],
        fieldNum: 0,
        errorOne: {},
        errorTwo: {},
        errorThree: {},
        errorFour: {},
        amountError: {},
      };
    },
    emits: ["close-send-deal"],
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      dealInfo: {
        type: Object,
        required: false,
      },
      member: {
        type: Object,
        required: true,
      },
      deals: { type: Array, required: false },
    },

    validations() {
      return {
        digit: {
          firstDigit: { required, maxLength: maxLength(1) },
          secondDigit: { required, maxLength: maxLength(1) },
          thirdDigit: { required, maxLength: maxLength(1) },
          forthDigit: { required, maxLength: maxLength(1) },
        },
        amount: {
          required,
        },
      };
    },
    directives: {
      focus: {
        mounted(el) {
          el.focus();
        },
      },
    },
    computed: {
      dealLeft() {
        let dealLeft = null;
        if (this.dealInfo.raise_amount && this.dealInfo.total_raised) {
          dealLeft = this.dealInfo.raise_amount - this.dealInfo.total_raised;
        } else {
          dealLeft = 0;
        }
        return dealLeft;
      },
    },

    mounted() {
      this.fields = this.$refs.codeInput.querySelectorAll("input");
      this.fieldNum = this.fields.length;
    },
    methods: {
      convertToLocale,
      ...mapActions("Deals", ["transferToken"]),
      ...mapActions(["showToast", "verifyPin"]),
      handlePaste(value) {
        this.code = "";
        for (let index = 0; index < this.fieldNum; index++) {
          const payload = { digit: value[index], position: index + 1 };
          this.setDigit(payload);
        }
      },

      setDigit(payload) {
        const keyCode = payload.$event ? payload.$event.inputType : null;
        switch (payload.position) {
          case 1:
            this.digit.firstDigit = payload.digit;
            this.v$.digit.firstDigit.$touch();
            this.errorOne = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              return;
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 2:
            this.digit.secondDigit = payload.digit;
            this.v$.digit.secondDigit.$touch();
            this.errorTwo = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 3:
            this.digit.thirdDigit = payload.digit;
            this.v$.digit.thirdDigit.$touch();
            this.errorThree = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 4:
            this.digit.forthDigit = payload.digit;
            this.v$.digit.forthDigit.$touch();
            this.errorFour = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            }
            break;
        }
      },

      concatDigits() {
        this.code = this.digit.firstDigit
          .concat(this.digit.secondDigit)
          .concat(this.digit.thirdDigit)
          .concat(this.digit.forthDigit);
      },

      verifyCode() {
        this.v$.$validate();
        const hasValue = Object.keys(this.digit).every(
          (key) => this.digit[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.amountError = {};
          this.verifyUserPin();
        } else {
          this.v$.$errors.map((error) => {
            if (error.$property === "firstDigit") this.errorOne = error;
            if (error.$property === "secondDigit") this.errorTwo = error;
            if (error.$property === "thirdDigit") this.errorThree = error;
            if (error.$property === "forthDigit") this.errorFour = error;
            if (error.$property === "amount") this.amountError = error;
          });
        }
      },
      async verifyUserPin() {
        this.loading = true;
        try {
          await this.verifyPin({ pin: this.code });
          this.sendMemberToken();
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
      async sendMemberToken() {
        const memberId = this.member.id.split("/")[2];
        const obj = {
          symbol: this.dealInfo ? this.dealInfo.symbol : this.deal,
          to: memberId,
          amount: this.amount,
        };
        try {
          const response = await this.transferToken(obj);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.loading = false;
          this.$emit("close-send-deal");
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .send-deal {
    width: 300px;
    @media (min-width: 768px) {
      width: 435px;
    }
    &__input {
      border: 1px solid #dde1e9;
      box-sizing: border-box;
      border-radius: 4px;
      -moz-appearance: textfield;
      &::placeholder {
        color: #a3a8b3;
        font-size: 14px;
      }
    }
  }
</style>
