<template>
  <div class="tw-relative tw-flex tw-flex-col">
    <label :for="label"></label>
    <input
      :type="type"
      class="tw-text-sm tw-rounded-lg tw-text-center tw-w-10 tw-h-12 sm:tw-h-12 sm:tw-w-12 tw-mb-3 focus:tw-border-primary tw-bg-white input"
      :class="{
        'tw-border-error': showError === true,
      }"
      :id="position"
      :value="digit"
      @input="updateDigit($event.target.value)"
      @keypress="isNumber($event)"
      @paste="handlePaste($event)"
      maxlength="1"
      minlength="1"
      placeholder="0"
      :disabled="disabled"
      required
    />
  </div>
</template>

<script>
import { isNumber } from "@/utils/helpers.js";

export default {
  name: "OnboardingCodeInput",

  emits: ["input", "handlePaste"],

  props: {
    label: { type: String, default: () => "", required: true },
    type: { type: String, default: () => "", required: true },
    digit: { type: String, default: () => "", required: true },
    error: { type: Object, default: () => {}, required: true },
    position: { type: Number, default: () => 0, required: true },
    disabled: { type: Boolean, default: () => false },
  },

  data() {
    return {
      showError: false,
      errorMsg: "",
    };
  },

  methods: {
    isNumber,

    updateDigit(value) {
      this.$emit("input", { digit: value, position: this.position });
    },

    handlePaste(event) {
      event.preventDefault();
      const paste = event.clipboardData.getData("text");
      this.$emit("handlePaste", paste);
    },

    checkError() {
      // Implementation for checking errors based on this.error.$property
      // (assuming it works correctly based on the provided code)
    },
  },

  watch: {
    error(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue) {
        this.checkError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  border: 1px solid #00258a;
}
</style>
