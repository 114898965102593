export default {
    data() {
        return {
            showSearchField: false,
            timeout: null,
        };
    },
    methods: {
        startTimeout() {
            if (this.showSearchField) {
                this.timeout = setTimeout(() => {
                    this.showSearchField = false;
                }, 3000);
            }
        },
        deleteTimeout() {
            clearTimeout(this.timeout);
            this.timeout = null;
        },
    },
    beforeDestroy() {
        this.deleteTimeout();
    },
};